@import '_breakpoints.scss';



.formField {
    width: 100%;
    margin-bottom: 10px;

    .demoLabel {
        width: 220px;
        display: inline-block;
        font-weight: bold;
        font-size: 0.8em;
        padding-bottom: 5px;
    }

    .select {
        padding-bottom: 5px;
    }
}
@media screen and (min-width: $desktop) {
    .formField {
        width: 48%;
    }
}


.demoFtr {
    margin: 20px;
    display: flex;

    .proceed {
        width: 150px;
        height: auto;
        padding: 13px;
    }
}