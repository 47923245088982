$primary : #000000;
$secondary : #FFFFFF;
$intermediate : #777777;
$shadow : #888888;
$ghosted : #DDD;
  
$focus : #000000;
$focus-bg : #FFFFFF;
  
$done : #00D100;

// Buttons
$primary-action :  #00D100;
$cancel-action : #ff0000;

//Nottingham University Palette
$nu-background : #10263b; 
$nu-foreground : #ffffff;
$nu-soft : #add7ff;