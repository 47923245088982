@import '../_colours.scss';
.card {
  display: flex;
  height: 100px;  
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $nu-foreground;
  border: 20px solid;
  border-radius: 10px;
  color: $nu-background;
  user-select: none;


  .cardCategory {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    font-weight: 100;
    color: $nu-background;
  }

  .statement {
    margin: 10px;
    font-weight: bold;
    font-size: large;
    line-height: 1.5;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  };
}