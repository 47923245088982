@import './_colours.scss';

.usabilityQuestions {
  width: 80%;
  margin: auto;
}

.usabilityQuestion {
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px 10px 0px;
}

.usabilityQuestion.even {
  background-color: $nu-soft;
}

.usabilityQuestion.odd {
  background-color: $ghosted;
}
.question {
  font-weight: bold;
  font-size: 0.9em;
}