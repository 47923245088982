@import '../_colours.scss';

.sliderContainer {
  background-color: $ghosted;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px;
}

.sliderAnswer {
    display: flex;
    justify-content: center;
    height: 65px
  }

.sliderEnds {
  width: 50px;
  font-size: 30px;
  text-align: center;
  padding-top: 15px;
}

.likertScale {
  width: 260px;
}

.likertLine {
  border-top: 3px solid $nu-background;
  margin-top: 0.4em;
}

.likertIndicator {
  background: $nu-background;
  border: thin solid $nu-background;
  border-radius: 4px;
}

.likertResponse {
  min-width: auto;

}
.likertResponse > input:checked + .likertIndicator {
  background: $done;

}
.likertText {
  font-size: 0.5em;
}

