@import '../_colours.scss';

.yesNoAnswer {
  margin: auto;
}

.yesNoRadios {
  display: flex;
  align-items: center;
  justify-content: center;
}

.yesNoRadios label {
margin: 5px 20px 0px 20px;
}

.ifNoText {
  font-size: 0.7em;
  margin-top: 5px;
}

.freetext {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  resize: none;
}

.ifNoText.disabled {
  display: none;
}

.freetext.disabled {
  display: none;
}
