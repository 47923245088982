@import '_colours.scss';
@import '_breakpoints.scss';

body {
    margin: 0;
    font-family: Verdana;
    color: $nu-background;
  }

.App {
  margin: 5px;
}

.content {
  max-width: $desktop;
  margin: auto;
}

.header .banner {
  height: 65px;
  background-color: $nu-background;
  color: $nu-foreground;
  position: relative;
  display: flex;
  align-items: center;

  h1 {
    font-size: 1.3em;
    text-align: left;
    line-height: 1.2em;
    padding: 0;
    margin: 0;
    width: 50%;
    padding: 14px;
  }

  .image {
    width: 40%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $nu-foreground;
    padding: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }

  }
}

.sectionHeading {
  width: 100%;
  background-color: $nu-soft;
  color: $primary;
  font-size: 1.1em;
  text-align: left;
  padding: 10px;
  font-weight: "bold";
}

.contentRow {
  display: block;
  margin-bottom: 10px;
  flex-direction: row;
}

.reverseOrder {
  flex-direction: row-reverse;
}

.contentBlock {
  margin: 10px;
} 

@media screen and (min-width: $desktop) {
  h1 {
    font-size: 2em;
  }

  .contentRow {
    display: flex;
  }

  .contentBlock {
    flex: 1;
  }
}

.accordion {
  margin: 10px;
  border-radius: 2px;

  .accordion__button { 
    background-color: $nu-background;
    color: $nu-foreground;
    cursor: pointer;
    padding: 18px;
    text-align: left;
    border: 1px solid $nu-foreground;
    font-size: 0.8em;
  }

  .accordion__button::before {
  display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }
  .accordion__button[aria-expanded='true']::before,
   .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
    background-color: $nu-soft;
  }
}