@import '../_colours.scss';

.answer {
    width: 2145px;
    height: 20px;
    text-align: center;
    background-color: $secondary;
    color: $primary;
    border-radius: 5px;
    padding: 10px 0px;
    margin: 5px 0px;
    font-size: medium;
    cursor: pointer;
    border: 1px solid $nu-background;
    user-select: none;
  
  }
  
  .answer:hover {
    border: 1px solid;
    border-color: $primary;
    box-shadow: 4px 4px 6px 3px $shadow;
  
  }
  
  .answerSelected {
    background-color: $nu-background;
    color: $nu-foreground;
  }

  @media (min-width: 390px){
    .answer {
      width: 180px;
    }
  }