@import './_colours.scss';
header {
    width: 100%;
    max-width: 800px;
    height: 50px;
    display: flex;
    margin: auto;
    margin-bottom: 20px;
  
    .progressBar {
      width: 90%;
      margin: 0 2px;
      background-color: #eee;
      height: 100%;
      position: relative;
      margin-bottom: 10px;   
      
      .progress {
        height: 100%;
        text-align: right;
      }
  
      .label {
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        color: $nu-background;
      }
  
    }
  
    .cardChangeBtn {
      width: 75px;
  
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
  
      background-color: $nu-background;
      color: $nu-foreground;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      user-select: none;
    }
  
    .disabled {
      background-color: $ghosted;
      cursor: not-allowed;
    }
}

.instruction {
  font-size: 0.7em;
  margin-bottom: 10px;
}

.cardList {
    position: relative;
    display: flex;
    height: 140px;
    margin: auto;
    justify-content: center;  
}
  
.answers {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0px auto 0px auto;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
  
}